import { ChainId, useEthers } from '@usedapp/core'
import { SupportedChainId } from 'constants/chain'
import { isChainSupported } from 'utils/isChainSupported'

export const useChainId = (): SupportedChainId => {
  const { chainId = ChainId.Mainnet } = useEthers()

  if (!isChainSupported(chainId)) {
    return ChainId.Mainnet
  }

  return chainId
}
