interface EnvironmentConfig {
  infuraApiKey: string | undefined
  infuraBackendApiKey: string | undefined
  isStaging: boolean
  isDevelopment: boolean
  isProduction: boolean
  isTest: boolean
}

const INFURA_API_KEY = process.env.NEXT_PUBLIC_INFURA_API_KEY
const INFURA_BACKEND_API_KEY = process.env.INFURA_BACKEND_API_KEY
const IS_STAGING = process.env.NEXT_PUBLIC_NODE_ENV === 'staging'
const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_NODE_ENV === 'dev'
const IS_PRODUCTION = process.env.NEXT_PUBLIC_NODE_ENV === 'production'
const IS_TEST = process.env.NEXT_PUBLIC_NODE_ENV === 'test'

export const environment: EnvironmentConfig = {
  infuraApiKey: INFURA_API_KEY,
  infuraBackendApiKey: INFURA_BACKEND_API_KEY,
  isStaging: IS_STAGING,
  isDevelopment: IS_DEVELOPMENT,
  isProduction: IS_PRODUCTION,
  isTest: IS_TEST,
}
