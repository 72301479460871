import { IconProps } from '../Icon'

export const LoaderIcon = ({ size = 64, className }: Pick<IconProps, 'size' | 'className'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 70 70"
      className={className}
    >
      <rect x="5" y="5" width="60" height="60" stroke="#686D6C" strokeWidth="10" fill="none" />
      <rect x="5" y="5" width="60" height="60" stroke="#E9E9E9" strokeWidth="10" strokeLinecap="square" fill="none">
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="1s"
          keyTimes="0;0.5;1"
          values="24 216;120 120;24 216"
        />
        <animate
          attributeName="stroke-dashoffset"
          repeatCount="indefinite"
          dur="1s"
          keyTimes="0;0.5;1"
          values="0;-120;-240"
        />
      </rect>
    </svg>
  )
}
