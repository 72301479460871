import { ReactNode } from 'react'
import { DAppProvider } from '@usedapp/core'
import { getDAppConfig } from 'config'
import { isServerSideRender } from 'utils'
import { WalletConnectorProvider } from './WalletConnectorProvider'
import { StorageProvider } from 'providers/Storage/provider'

export const BrowserProviders = ({ children }: { children: ReactNode }) => {
  if (isServerSideRender()) {
    return <>{children}</>
  }

  return (
    <DAppProvider config={getDAppConfig()}>
      <WalletConnectorProvider>
        <StorageProvider>{children}</StorageProvider>
      </WalletConnectorProvider>
    </DAppProvider>
  )
}
