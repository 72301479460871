import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs'
import { Database } from 'integrations/supabase/generated.types'
import { useMemo } from 'react'
import { supabaseConfig } from '../config/supabase'

export const useCreateBrowserSupabaseClient = () => {
  return useMemo(
    () =>
      createPagesBrowserClient<Database>({
        supabaseUrl: supabaseConfig?.url,
        supabaseKey: supabaseConfig?.anonKey,
      }),
    [],
  )
}
