export const zIndexes = {
  belowContent: '-1',
  content: '1',
  aboveContent: '2',
  banner: '3',
  stickyHeader: '4',
  aboveStickyHeader: '5',
  dropdown: '10',
  aboveDropdown: '15',
  menu: '30',
  modalBackground: '40',
  modalContent: '50',
  notification: '60',
  loadingProgressBar: '70',
}
