import Script from 'next/script'
import { environment } from 'config'
import { mazeConfig } from 'config/maze'
import { useEffect } from 'react'

// Script created based on https://help.maze.co/hc/en-us/articles/9800356063891
// It's for embedded live surveys for user feedback using maze
export const MazeScript = () => {
  useEffect(() => {
    if (!environment.isStaging) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyWindow = window as any
    anyWindow.mazeUniversalSnippetApiKey = mazeConfig.tracingCode
  }, [])

  if (!environment.isStaging) {
    return null
  }

  const currentTime = new Date().getTime().toString()
  return <Script src={`${mazeConfig.snippetBaseUrl}?t=${currentTime}&apiKey=${mazeConfig.tracingCode}`}></Script>
}
