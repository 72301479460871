import { useCallback } from 'react'

import { CheckCircleIcon, CloseIcon, ErrorIcon, InfoIcon, LoaderIcon } from 'components/atoms/Icons'
import {
  Close,
  NotificationDescription,
  NotificationIconWrapper,
  NotificationTitle,
  Toast,
  ToastContent,
} from './components'
import { useChainId } from 'hooks'
import { Notification, useNotifications } from 'providers/NotificationsProvider'
import { useTheme } from 'styled-components'
import { theme } from 'styles'

export interface Props {
  notification: Notification
}

export const NotificationToast = ({ notification }: Props) => {
  const chainId = useChainId()
  const { removeNotification } = useNotifications()
  const removeNotificationWithDelay = useCallback(
    () => setTimeout(() => removeNotification({ chainId, notificationId: notification.id }), 500),
    [chainId, notification.id, removeNotification],
  )
  const { title, description, duration } = notification
  const notificationDuration = duration ?? 5000

  const handleClose = useCallback(
    (open: boolean) => {
      if (!open) {
        removeNotificationWithDelay()
      }
    },
    [removeNotificationWithDelay],
  )

  return (
    <Toast onOpenChange={handleClose} duration={notificationDuration} $type={notification.type}>
      <NotificationIconWrapper>
        <NotificationIcon notification={notification} />
      </NotificationIconWrapper>
      <ToastContent>
        <NotificationTitle $type={notification.type}>{title}</NotificationTitle>
        {description && <NotificationDescription $type={notification.type}>{description}</NotificationDescription>}
      </ToastContent>
      <Close>
        <CloseIcon
          color={notification.type == 'backendError' ? theme.colors.PersianRed : theme.colors.White}
          size={24}
        />
      </Close>
    </Toast>
  )
}

const NotificationIcon = ({ notification }: Props) => {
  const theme = useTheme()

  switch (notification.type) {
    case 'error':
    case 'backendError':
      return <ErrorIcon size={19} color={theme.colors.PersianRed} />
    case 'info':
      return <InfoIcon size={19} color={theme.colors.MonteCarlo} />
    case 'pending':
      return <LoaderIcon size={19} />
    case 'success':
      return <CheckCircleIcon size={19} color={theme.colors.Emerald} />
  }
}
