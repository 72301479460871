export const COLORS = {
  White: 'hsl(0, 0%, 100%)',
  Black: 'hsl(0, 0%, 0%)',
  GrayNurse: 'hsl(148, 3%, 97%)',
  Mouse: 'hsl(0, 0%, 91%)',
  Iron: 'hsl(180, 3%, 82%)',
  Edward: 'hsl(168, 2%, 62%)',
  Corduroy: 'hsl(162, 3%, 42%)',
  CapeCod: 'hsl(166, 2%, 28%)',
  HeavyMetal: 'hsl(120, 3%, 6%)',
  Transparent: 'transparent',

  // Secondary
  AquaSpring: 'hsl(180, 55%, 96%)',
  JaggedIce: 'hsl(180, 51%, 86%)',
  MonteCarlo: 'hsl(180, 46%, 68%)',
  OxidizedGreen: 'hsl(180, 69%, 30%)',
  Elm: 'hsl(180, 95%, 16%)',

  // Tertiary
  ForgetMeNot: 'hsl(16, 100%, 96%)',
  MandysPink: 'hsl(17, 73%, 86%)',
  TonysPink: 'hsl(17, 71%, 74%)',
  CopperOrange: 'hsl(16, 52%, 47%)',
  Crail: 'hsl(17, 55%, 32%)',

  // Notifications
  PersianRed: 'hsl(0, 59%, 49%)',
  Tomato: 'hsla(0, 80%, 59%)',
  Fulvous: 'hsl(33, 91%, 46%)',
  Emerald: 'hsl(147, 33%, 51%)',
  MistyRose: 'hsl(0, 100%, 96%)',
  Antique: 'hsl(35, 100%, 95%)',
  Honeydew: 'hsl(148, 100%, 95%)',
  Cornflower: 'hsl(220, 64%, 62%)',
  Slate: 'hsl(242, 50%, 56%)',
  Purpureus: 'hsl(303, 39%, 46%)',

  // Text
  Text0: 'hsl(180, 2%, 62%)',
  Text01: 'hsl(162, 3%, 42%)',
  Text02: 'hsl(166, 2%, 28%)',
  Text03: 'hsl(120, 3%, 6%)',

  Porcelain: 'hsl(222, 33%, 93%)',
}
