import { COLORS } from './colors'
import { QUERIES } from './mediaQueries'
import { SIDEBAR_WIDTH } from './sidebarWidth'

export type CustomTheme = typeof theme

export const theme = {
  colors: {
    // Primary/Neutrals
    White: `${COLORS.White}`,
    Black: `${COLORS.Black}`,
    GrayNurse: `${COLORS.GrayNurse}`,
    Mouse: `${COLORS.Mouse}`,
    Iron: `${COLORS.Iron}`,
    Edward: `${COLORS.Edward}`,
    Corduroy: `${COLORS.Corduroy}`,
    CapeCod: `${COLORS.CapeCod}`,
    HeavyMetal: `${COLORS.HeavyMetal}`,
    Transparent: COLORS.Transparent,

    // Secondary
    AquaSpring: `${COLORS.AquaSpring}`,
    JaggedIce: `${COLORS.JaggedIce}`,
    MonteCarlo: `${COLORS.MonteCarlo}`,
    OxidizedGreen: `${COLORS.OxidizedGreen}`,
    Elm: `${COLORS.Elm}`,

    // Tertiary
    ForgetMeNot: `${COLORS.ForgetMeNot}`,
    MandysPink: `${COLORS.MandysPink}`,
    TonysPink: `${COLORS.TonysPink}`,
    CopperOrange: `${COLORS.CopperOrange}`,
    Crail: `${COLORS.Crail}`,

    // Notifications
    PersianRed: `${COLORS.PersianRed}`,
    Tomato: `${COLORS.Tomato}`,
    Fulvous: `${COLORS.Fulvous}`,
    Emerald: `${COLORS.Emerald}`,
    MistyRose: `${COLORS.MistyRose}`,
    Antique: `${COLORS.Antique}`,
    Honeydew: `${COLORS.Honeydew}`,
    Cornflower: `${COLORS.Cornflower}`,
    Slate: `${COLORS.Slate}`,
    Purpureus: `${COLORS.Purpureus}`,

    // Text
    Text0: `${COLORS.Text0}`,
    Text01: `${COLORS.Text01}`,
    Text02: `${COLORS.Text02}`,
    Text03: `${COLORS.Text03}`,

    Porcelain: `${COLORS.Porcelain}`,

    NotificationBackground: 'hsla(166, 2%, 28%, 0.9)',
    DarkNotificationBackground: 'hsl(180,95%,16%)',
    ModalOverlay: 'hsla(120, 3%, 6%, 0.6)',
    TableBackground: 'hsla(148, 3%, 97%, 0.4)',
    TableHeaderBackground: 'hsl(120, 6%, 97%)',
    InfoBoxBackground: 'hsla(0, 0%, 15%, 0.8)',
    InfoBox: 'hsla(0, 0%, 15%)',
    DarkMessageBackgrounds: {
      Error: 'hsla(0, 59%, 49%, 0.1)',
      Info: 'hsla(180, 95%, 16%, 0.2)',
      Warning: 'hsla(33, 91%, 46%, 0.1)',
      Success: 'hsla(147, 33%, 51%, 0.1)',
      Default: 'hsla(148, 3%, 97%, 0.1)',
    },
  },
  // Media Queries
  queries: QUERIES,
  sidebarWidth: SIDEBAR_WIDTH,
}
