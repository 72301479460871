import { useEffect } from 'react'
import NProgress from 'nprogress'
import { useRouter } from 'next/router'

export function useProgressBar() {
  const { events } = useRouter()

  useEffect(() => {
    const handleRouteStart = () => NProgress.start()
    const handleRouteDone = () => NProgress.done()

    events.on('routeChangeStart', handleRouteStart)
    events.on('routeChangeComplete', handleRouteDone)
    events.on('routeChangeError', handleRouteDone)

    return () => {
      events.off('routeChangeStart', handleRouteStart)
      events.off('routeChangeComplete', handleRouteDone)
      events.off('routeChangeError', handleRouteDone)
    }
  }, [events])
}
