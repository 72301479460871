import { ChainId, Config, Connector, Mainnet, MetamaskConnector, Sepolia } from '@usedapp/core'
import { getDefaultProvider, providers } from 'ethers'

import { environment } from './environment'
import { SUPPORTED_CHAINS } from 'constants/chain'
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector'

// Config loaded dynamically by the BrowserProviders.
export const getDAppConfig = (): Config => ({
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: getProvider('mainnet'),
    [Sepolia.chainId]: getProvider('sepolia'),
  },
  connectors,
  networks: SUPPORTED_CHAINS,
  multicallVersion: 2,
  fastMulticallEncoding: true,
})

function getProvider(network: providers.Networkish & string) {
  if (!environment.infuraApiKey) {
    return getDefaultProvider(network)
  }
  return providers.InfuraProvider.getWebSocketProvider(network, environment.infuraApiKey)
}

export type SupportedConnector = 'Metamask' | 'WalletConnectV2'

const chains = [environment.isProduction ? Mainnet : Sepolia]
const connectors: Record<SupportedConnector, Connector> = {
  Metamask: new MetamaskConnector(),
  WalletConnectV2: new WalletConnectV2Connector({
    projectId: '0db814df67c2c61ade89ddfb5e158cc2',
    rpcMap: {
      [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${environment.infuraApiKey}`,
      [ChainId.Sepolia]: `https://sepolia.infura.io/v3/${environment.infuraApiKey}`,
    },
    chains,
    checkGnosisSafe: true,
  }),
}
