export const BREAKPOINTS = {
  mobileAndSmaller: 550,
  largeMobileAndSmaller: 768,
  tabletAndSmaller: 1100,
  laptopAndSmaller: 1280,
  desktopAndSmaller: 1440,
}

export const QUERIES = {
  mobileAndSmaller: `(max-width: ${BREAKPOINTS.mobileAndSmaller / 16}rem)`,
  largeMobileAndSmaller: `(max-width: ${BREAKPOINTS.largeMobileAndSmaller / 16}rem)`,
  tabletAndSmaller: `(max-width: ${BREAKPOINTS.tabletAndSmaller / 16}rem)`,
  laptopAndSmaller: `(max-width: ${BREAKPOINTS.laptopAndSmaller / 16}rem)`,
  desktopAndSmaller: `(max-width: ${BREAKPOINTS.desktopAndSmaller / 16}rem)`,
  touchscreen: `(pointer: coarse)`,
}
