import * as ToastPrimitive from '@radix-ui/react-toast'
import styled from 'styled-components'

import { NotificationToast } from 'components/molecules/NotificationToast'
import { QUERIES, zIndexes } from 'styles'
import { NotificationsContext } from './context'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { RemoveNotificationPayload } from '@usedapp/core'
import { AddNotificationType, Notification } from './types'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { getNotificationByType } from 'utils/notifications/getNotificationByType'

const ONE_SECOND = 1000

function areNotificationsDuplicated(first: Notification, second: Notification) {
  return (
    first.title === second.title &&
    first.type === second.type &&
    Math.abs(first.submittedAt - second.submittedAt) < ONE_SECOND
  )
}

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const supabaseClient = useSupabaseClient()
  const [notifications, setNotifications] = useState<Notification[]>([])

  supabaseClient
    .channel('notifications')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'Notification',
      },
      (payload) => {
        const notification = getNotificationByType(payload.new.type)

        if (notification) {
          addNotification(notification)
        }
      },
    )
    .subscribe()

  const addNotification = useCallback((notification: AddNotificationType) => {
    const submittedAt = Date.now()
    const id = `app-${submittedAt}`
    setNotifications((notifications) => {
      const newNotification = {
        submittedAt,
        id,
        ...notification,
      }
      const duplicateFound = !!notifications.find((notification) =>
        areNotificationsDuplicated(notification, newNotification),
      )
      return duplicateFound ? notifications : [...notifications, newNotification]
    })
    return id
  }, [])
  const removeNotification = useCallback((payload: RemoveNotificationPayload) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== payload.notificationId),
    )
  }, [])

  const value = useMemo(
    () => ({
      notifications,
      removeNotification,
      addNotification,
    }),
    [addNotification, notifications, removeNotification],
  )

  return (
    <NotificationsContext.Provider value={value}>
      <ToastPrimitive.Provider>
        {notifications.map((notification) => (
          <NotificationToast key={notification.id} notification={notification} />
        ))}
        <NotificationsList />
        {children}
      </ToastPrimitive.Provider>
    </NotificationsContext.Provider>
  )
}

const NotificationsList = styled(ToastPrimitive.Viewport)`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 100%;
  max-width: 320px;
  margin: 0;
  padding: 0;
  z-index: ${zIndexes.notification};

  ${QUERIES.mobileAndSmaller} {
    right: 16px;
    max-width: calc(100% - 32px);
    width: calc(100% - 32px);
  }
`
