export type SupabaseConfig = {
  url: string
  anonKey: string
  appRoleKey: string | null
}

const supabaseURL = process.env.NEXT_PUBLIC_SUPABASE_URL ?? null
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? null
const appRoleKey = process.env.SUPABASE_APP_ROLE_KEY ?? null

export const supabaseConfig: SupabaseConfig | null =
  supabaseURL && supabaseKey
    ? {
        url: supabaseURL,
        anonKey: supabaseKey,
        appRoleKey,
      }
    : null
