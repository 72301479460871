import { createContext, useContext } from 'react'
import { AddNotificationType, Notification } from './types'
import { RemoveNotificationPayload } from '@usedapp/core'

interface NotificationsContextProps {
  notifications: Notification[]
  removeNotification: (payload: RemoveNotificationPayload) => void
  addNotification: (notification: AddNotificationType) => string
}

export const NotificationsContext = createContext<NotificationsContextProps>({
  notifications: [],
  removeNotification: () => null,
  addNotification: () => '',
})

export const useNotifications = () => useContext(NotificationsContext)
