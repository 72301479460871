import { ReactNode, useCallback, useMemo, useState } from 'react'

import { StorageContext } from './context'

interface Props {
  children: ReactNode
}

export const StorageProvider = ({ children }: Props) => {
  const [state, setState] = useState<Record<string, unknown>>({})

  const setValue = useCallback(
    (key: string, value: unknown) => {
      setState({ ...state, [key]: value })
    },
    [state],
  )
  const getValue = useCallback((key: string) => state[key], [state])

  const removeItem = useCallback((key: string) => {
    setState((current) => {
      const copy = { ...current }
      delete copy[key]
      return copy
    })
  }, [])

  const value = useMemo(
    () => ({
      setValue,
      getValue,
      removeItem,
    }),
    [setValue, getValue, removeItem],
  )
  return <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
}
