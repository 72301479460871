import { ReactNode, useMemo } from 'react'
import { WalletConnectorContext } from './context'
import { useConnector, useEthers } from '@usedapp/core'
import { SupportedConnector } from 'config'

export const WalletConnectorProvider = ({ children }: { children: ReactNode }) => {
  const { connector } = useConnector()
  const { deactivate, account, activateBrowserWallet } = useEthers()

  const connectors = useMemo(
    () => ({
      Metamask: {
        activate: () => activateBrowserWallet({ type: 'Metamask' }),
        name: 'MetaMask',
      },
      WalletConnectV2: {
        activate: () => activateBrowserWallet({ type: 'WalletConnectV2' }),
        name: 'WalletConnectV2',
      },
    }),
    [activateBrowserWallet],
  )

  const value = useMemo(
    () => ({
      connectors,
      activeConnector: connectors[connector?.connector.name as SupportedConnector]?.name ?? null,
      deactivate,
      account,
    }),
    [account, connector?.connector.name, connectors, deactivate],
  )

  return <WalletConnectorContext.Provider value={value}>{children}</WalletConnectorContext.Provider>
}
