import { captureException } from '@sentry/nextjs'
import { ResponseError as TcResponseError } from 'generated/openapi/truecoin'
import { ResponseError as PmResponseError } from 'generated/openapi/parallelMarkets'
import { environment } from 'config'
import { isSupabasePostgrestError } from 'integrations/supabase/utils/isSupabasePostgrestError'

type Extra = unknown
type Extras = Record<string, Extra>

export async function sendExceptionToSentry(maybeError: unknown, extras?: Extras) {
  const { error, extra } = await prepareError(maybeError, extras)

  if (environment.isDevelopment) {
    // eslint-disable-next-line no-console
    console.error(error, extra)
    return
  }

  captureException(error, { extra })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function prepareError(error: any, extra?: Extras) {
  if (isSupabasePostgrestError(error)) {
    const { message, ...extras } = error
    extra = { ...extra, ...extras }
    error = new Error(message)
  }

  if (error instanceof TcResponseError || error instanceof PmResponseError) {
    const extras = await getResponseExtras(error)
    extra = { ...extra, ...extras }
  }

  if (typeof error === 'string') {
    error = new Error(error)
  }

  const isRequestError = 'response' in error
  if (isRequestError) {
    extra = { ...extra, body: error.response.body, headers: error.response.headers }
  }

  const isErrorInstance = error instanceof Error
  if (!isErrorInstance) {
    extra = { ...extra, error }
    error = new Error('Non-Error thrown')
  }

  return { error, extra }
}

const getResponseExtras = async (error: TcResponseError | PmResponseError) => {
  const errorMessage =
    error.response.headers.get('content-type') === 'application/json'
      ? JSON.stringify(await error.response.json())
      : await error.response.text()

  return {
    response: `[${error.response.status}] ${errorMessage}`,
    stack: error.stack,
  }
}
