import { createGlobalStyle } from 'styled-components'
import { zIndexes } from 'styles/zIndexes'

export const NProgressBarStyles = createGlobalStyle`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${({ theme }) => theme.colors.MonteCarlo};
    position: fixed;
    z-index: ${zIndexes.loadingProgressBar};
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
`
