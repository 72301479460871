import { sendExceptionToSentry } from 'utils/api/sendExceptionToSentry'

export class ClientSideBackendError extends Error {
  action: string
  thrownError: unknown
  options?: { isSilent: boolean }

  constructor(action: string, error: unknown, options: { isSilent: boolean } = { isSilent: false }) {
    super(action)
    this.action = action
    this.thrownError = error
    this.options = options
  }
}

export const getClientSideErrorDetails = (error: unknown) => {
  return {
    title: `Something went wrong`,
    description: (
      <>
        <p>
          An error occurred while {error instanceof ClientSideBackendError ? error.action : 'handling your request'}.
        </p>
        <p>For help, please email us at support@archblock.com</p>
      </>
    ),
  }
}

export function sendNotificationErrorToSentry(error: unknown) {
  if (error instanceof ClientSideBackendError) {
    return sendExceptionToSentry(error.thrownError || error.action)
  }
  return sendExceptionToSentry(error)
}
