import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'
import { useNotifications } from 'providers/NotificationsProvider'
import {
  ClientSideBackendError,
  getClientSideErrorDetails,
  sendNotificationErrorToSentry,
} from 'providers/CustomQueryClientProvider/error'

export interface Props {
  children: ReactNode
}

/**
 * Adds global error handling on top of the default QueryClient.
 * It uses NotificationProvider and addNotification to display backend errors as popups
 */

export const CustomQueryClientProvider = ({ children }: Props) => {
  const [queryClient] = useState(() => new QueryClient())
  const { addNotification } = useNotifications()

  if (!queryClient.getDefaultOptions().queries?.onError || !queryClient.getDefaultOptions().mutations?.onError) {
    const handleDefaultOptionsError = () => ({
      onError: (error: unknown) => {
        if (error instanceof ClientSideBackendError) {
          const { options: { isSilent } = {} } = error

          if (!isSilent) {
            addNotification({
              type: 'backendError',
              duration: 10000,
              ...getClientSideErrorDetails(error),
            })
          }
        }
        void sendNotificationErrorToSentry(error)
      },
    })
    queryClient.setDefaultOptions({
      queries: handleDefaultOptionsError(),
      mutations: handleDefaultOptionsError(),
    })
  }

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
