import { AddNotificationType } from 'providers/NotificationsProvider'
import { Database } from 'integrations/supabase/generated.types'

export const getNotificationByType = (
  type: Database['public']['Enums']['NotificationType'],
): AddNotificationType | null => {
  switch (type) {
    case 'verificationCompleted':
      return {
        type: 'success',
        title: 'Identity Verified',
        description:
          'Your verification process is completed. You can now participate in offerings you are eligible for.',
        duration: 15000,
      }
    case 'verificationFailed':
      return {
        type: 'error',
        title: 'Identity Not Verified',
        description: 'Sorry, something went wrong. Please try again.',
        duration: 15000,
      }
    case 'investmentSignatureVerified':
      return {
        type: 'success',
        title: 'Signature Completed',
        description: 'A copy of the document was sent to your email address.',
        duration: 15000,
      }
    default:
      return null
  }
}
