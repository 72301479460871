import { ChainId, Mainnet, Sepolia } from '@usedapp/core'
import { isChainSupported } from 'utils/isChainSupported'

export type ProductionChainId = ChainId.Mainnet
export type StagingChainId = ChainId.Sepolia
export type TestingChainId = ChainId.Sepolia

export const SUPPORTED_CHAINS = [Mainnet, Sepolia]

export type SupportedChainId = ProductionChainId | StagingChainId | TestingChainId

export const SUPPORTED_CHAIN_NAME: { [chainId in SupportedChainId]: string } = {
  [ChainId.Mainnet]: 'mainnet',
  [ChainId.Sepolia]: 'sepolia',
}

export const CHAIN_DISPLAY_NAME: { [chainId in SupportedChainId]: string } = {
  [ChainId.Mainnet]: 'Ethereum',
  [ChainId.Sepolia]: 'Sepolia',
}

export function getChainDisplayName(chainId: ChainId) {
  if (!chainId || !isChainSupported(chainId)) return ''
  return CHAIN_DISPLAY_NAME[chainId]
}
