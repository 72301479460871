import * as ToastPrimitive from '@radix-ui/react-toast'
import { AddNotificationType } from 'providers/NotificationsProvider'
import styled, { keyframes } from 'styled-components'

import { BorderRadiuses, Overflows } from 'styles'
import { UseTransientProps } from 'types/utility'

const hide = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
})

const slideIn = keyframes({
  from: {
    opacity: 0,
    transform: 'translateX(100%)',
  },
  to: {
    opacity: 1,
    transform: 'translateX(0)',
  },
})

type StyledToastProps = UseTransientProps<Pick<AddNotificationType, 'type'>, 'type'>

export const Toast = styled(ToastPrimitive.Root)<StyledToastProps>`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  padding: 24px 24px 24px 64px;
  background: ${({ theme, $type }) =>
    $type == 'backendError' ? theme.colors.MistyRose : theme.colors.NotificationBackground};
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: ${BorderRadiuses.s};

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 0.2s ease-out;
    }

    &[data-state='closed'] {
      animation: ${hide} 0.2s ease-in forwards;
    }
  }
`

export const NotificationIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 24px;
  left: 24px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`

export const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

export const NotificationTitle = styled(ToastPrimitive.Title)<StyledToastProps>`
  margin: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme, $type }) => ($type == 'backendError' ? theme.colors.PersianRed : theme.colors.White)};
  ${Overflows.FullDots};
`

export const NotificationDescription = styled(ToastPrimitive.Description)<StyledToastProps>`
  margin: 12px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme, $type }) => ($type == 'backendError' ? theme.colors.PersianRed : theme.colors.Mouse)};
`

export const Close = styled(ToastPrimitive.Close)`
  position: absolute;
  top: 17.5px;
  right: 17.5px;
  padding: 0;
  color: ${({ theme }) => theme.colors.White};
  background: none;
  border: none;
`
