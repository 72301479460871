import { createContext } from 'react'

interface StorageContext {
  getValue: (key: string) => unknown
  setValue: (key: string, value: unknown) => void
  removeItem: (key: string) => void
}

export const StorageContext = createContext<StorageContext>({
  getValue: () => null,
  setValue: () => undefined,
  removeItem: () => undefined,
})
