import { createContext, useContext } from 'react'
import { SupportedConnector } from '../../config'

export type Connectors = Record<SupportedConnector, { activate: () => void; name: string }>

interface WalletConnectorProviderProps {
  connectors: Connectors
  activeConnector: string | null
  deactivate: () => void
  account: string | undefined
}

export const WalletConnectorContext = createContext<WalletConnectorProviderProps>({
  connectors: {} as Connectors,
  activeConnector: null,
  deactivate: () => null,
  account: undefined,
})

export const useWalletConnector = () => useContext(WalletConnectorContext)
